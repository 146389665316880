<template>
    <div>
        <Navbar page="Novo template de diagnóstico" link="/diagnosticos/gerenciar" nameLink="Gerenciar templates de diagnósticos" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    
                    <div class="flex flex-col gap-6 px-5 pb-4">
                        <div class="grid grid-cols-1 md:grid-cols-12 lg:grid-cols-12 gap-6 col-span-12 items-center">
                            <section class="col-span-1 md:col-span-12 lg:col-span-6">
                                <label for="tema" class="block text-sm font-medium">Tema</label>
                                <input 
                                    type="text" 
                                    v-model="form.tema" 
                                    name="tema" 
                                    id="tema" 
                                    autocomplete="off"  
                                    class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                                >
                            </section> 
                            <section class="col-span-1 md:col-span-12 lg:col-span-6 flex items-center gap-2">
                                <input type="checkbox" v-model="form.incluirPAI" class="rounded-sm cursor-pointer" id="incluirPAI">
                                <label for="incluirPAI" class="text-sm font-medium cursor-pointer">
                                    Considerar para Plano de Ação Integral - momento 1 (PAI -M1)
                                </label>
                            </section>
                        </div>
                       
                        <section class="flex flex-wrap gap-2 items-center">
                            <button type="button" @click="addPergunta" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                                Adicionar pergunta
                            </button>
                            <button type="button" @click="$modal.show('importarModal')" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                </svg> 
                                Importar perguntas 
                            </button>
                            <a v-if="$route.params.id && form.perguntas.length > 0" :href="`${url_api}/v1/diagnosticosBase/download/${$route.params.id}?token=${$store.state.token}`" type="button" role="button" target= "_blank" rel="noreferrer" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                </svg> 
                                Download perguntas 
                            </a>
                            <button type="button" @click="removerPerguntas" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                                Remover todas as perguntas
                            </button>

                            <div class="flex items-center gap-2">
                                <input type="checkbox" v-model="form.pesosManual" class="rounded-sm cursor-pointer" id="pesosManual">
                                <label for="pesosManual" class="text-sm font-medium cursor-pointer">
                                    Atribuir pesos às questões, manualmente
                                </label>
                            </div>
                        </section>
                    </div>

                    <hr class="pb-4 mx-5"/>
                   
                    <div v-for="(item, idx) in form.perguntas" :key="idx" class="grid grid-cols-12 gap-4 my-1 md:my-3 ">
                        <div class="col-span-12 lg:col-span-2">
                            <section class="flex items-center gap-4">
                                <label class="block text-sm font-bold">
                                    Questão: {{ idx+1 }}
                                </label>
                                <button type="button" @click="remove(idx)" class="text-white text-sm hover:bg-red-600 bg-red-500 rounded-full h-9 w-9 flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 " fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                </button>
                            </section>
                            <section v-if="form.pesosManual" class="">
                                <label class="block text-sm font-medium my-2">Peso: </label>
                                <div class="flex">
                                    <button type="button" @click="changePeso(item,'adicionar')" class="bg-green-600 text-white px-3 h-8 text-base font-medium text-center border-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                    </button>
                                    <div class="mx-2">
                                        <input v-model="item.peso" type="number" class="w-14 h-8 text-base font-medium text-center border-2">
                                    </div>
                                    <button type="button" @click="changePeso(item,'diminuir')" class="bg-red-600 text-white px-3 h-8 text-base font-medium text-center border-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                        </svg>
                                    </button>
                                </div>
                            </section>
                        </div>
                        <div class="col-span-12 lg:col-span-10 grid grid-cols-12 gap-2 items-end">
                            <div class="col-span-12 md:col-span-6 lg:col-span-4 ">
                                <label for="pergunta" class="block text-sm font-medium">Atribuir planos de ação Corrigir ortografia</label>
                                <textarea type="text" rows="3" v-model="item.pergunta" name="pergunta" id="pergunta" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                            </div>
                            <div class="col-span-12 md:col-span-6 lg:col-span-4 " v-if="form.incluirPAI">
                                <label for="vulnerabilidade" class="block text-sm font-medium">
                                    <b>Planos de ação (gerado por IA)</b>
                                    Vulnerabilidades detectadas
                                    <svg fill="#059669" viewBox="0 0 256 256" @click="novoItem('vulnerabilidades', idx)" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600 cursor-pointer">
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </label>
                                <multiselect 
                                    v-model="item.vulnerabilidades" 
                                    :options="item.vulnerabilidades" 
                                    :multiple="true" 
                                    :close-on-select="false" 
                                    :taggable="true" 
                                    @tag="adicionarVulnerabilidade(idx, $event)" 
                                    id="vulnerabilidade" 
                                    placeholder="Informe vulnerabilidade" 
                                    tagPlaceholder="Pressione Enter para criar" 
                                    noOptionsText="Nenhuma opção disponível" 
                                    selectedLabel="selecionado" 
                                    deselectLabel="Pressione Enter para remover" 
                                    selectLabel="Pressione Enter para selecionar" 
                                    class="mt-2 h-full focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                                />
                            </div>
                            <div class="col-span-12 md:col-span-6 lg:col-span-4 " v-if="form.incluirPAI">
                                <label for="respostaNao" class="block text-sm font-medium">
                                    Se resposta  "<b>não</b>"
                                    <svg fill="#059669" viewBox="0 0 256 256" @click="novoItem('respostaNao', idx)" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600 cursor-pointer">
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </label>
                                <multiselect 
                                    v-model="item.respostaNao" 
                                    :options="item.respostaNao" 
                                    :multiple="true" 
                                    :close-on-select="false" 
                                    :taggable="true" 
                                    @tag="adicionarRespostaNao(idx, $event)" 
                                    id="respostaNao" 
                                    placeholder="Informe se resposta 'não'" 
                                    tagPlaceholder="Pressione Enter para criar" 
                                    noOptionsText="Nenhuma opção disponível" 
                                    selectedLabel="selecionado" 
                                    deselectLabel="Pressione Enter para remover" 
                                    selectLabel="Pressione Enter para selecionar" 
                                    class="mt-2 h-full focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                                />
                            </div>
                            <!-- <div class="col-span-12 md:col-span-6 lg:col-span-3 flex flex-col" v-if="form.incluirPAI">
                                <label for="respostaParcial" class="block text-sm font-medium">
                                    Se resposta  "<b>parcialmente</b>"
                                    <svg fill="#059669" viewBox="0 0 256 256" @click="novoItem('respostaParcial', idx)" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600 cursor-pointer">
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </label>
                                <multiselect 
                                    v-model="item.respostaParcial" 
                                    :options="item.respostaParcial" 
                                    :multiple="true" 
                                    :close-on-select="false" 
                                    :taggable="true" 
                                    @tag="adicionarRespostaParcial(idx, $event)" 
                                    id="respostaParcial" 
                                    placeholder="Informe se resposta 'parcialmente'" 
                                    tagPlaceholder="Pressione Enter para criar" 
                                    noOptionsText="Nenhuma opção disponível" 
                                    selectedLabel="selecionado" 
                                    deselectLabel="Pressione Enter para remover" 
                                    selectLabel="Pressione Enter para selecionar" 
                                    class="mt-2 h-full focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                                />
                            </div> -->
                        </div>
                    </div>
                  
                    <div class="flex flex-wrap justify-end gap-4">
                        <div class="py-3 text-left">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="py-3 text-right">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                    
                </form>
            </div>
            <modal name="novoItem" :adaptive="true" :height="'auto'" :clickToClose="false">
                <div class="px-5 py-5">
                    <div>
                        <h2 class="text-xl font-bold mb-4"> 
                            Adicionar  
                            {{ formNovo.opcao === 'vulnerabilidades' ? 'vulnerabilidade': '' }}
                            {{ formNovo.opcao === 'respostaNao' ? 'resposta se não:': '' }}
                            <!-- {{ formNovo.opcao === 'respostaParcial' ? 'resposta se parcialmente': '' }} -->
                        </h2>
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12">
                                <label for="formNovodescricao" class="block text-sm font-medium">
                                    {{ formNovo.opcao === 'vulnerabilidades' ? 'Vulnerabilidade': '' }}
                                    {{ formNovo.opcao === 'respostaNao' ? 'Resposta se não:': '' }}
                                    <!-- {{ formNovo.opcao === 'respostaParcial' ? 'Resposta se parcialmente': '' }} -->
                                </label>
                                <input 
                                    v-model="formNovo.descricao" 
                                    type="text" 
                                    name="formNovodescricao" 
                                    id="formNovodescricao" 
                                    autocomplete="off" 
                                    class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div class="grid grid-cols-2 mt-3">
                        <div class="py-3 text-left">
                            <button @click="$modal.hide('novoItem')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="py-3 text-right">
                            <button 
                                @click="criarItem" 
                                type="button" 
                                class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Adicionar
                            </button>
                        </div>
                    </div>
                </div>
            </modal>
            <modal name="importarModal" :adaptive="true" :height="'auto'">
                <div class="px-5 py-5">
                    <h2 class="text-xl mb-2 font-bold"> Importar base</h2>
                    <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <a target= "_blank" rel="noreferrer" :href="`/arquivo-modelo-11.csv`" class="text-sm underline">Exemplo arquivo modelo importação</a>
                    </div>
                    <div class="col-span-12">
                        <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" @vdropzone-sending="send" v-on:vdropzone-success="update">
                        </vue-dropzone>
                    </div>
                    </div>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
    components: {
        vueDropzone
    },
    data() {
        return {
            route: 'diagnosticos',
            idx: 0,
            opcoesVulnerabilidades: [],
            form: {
                tema: '',
                incluirPAI: false,
                pesosManual: false,
                perguntas: [],
            },
            formNovo: {
                descricao: '',
                opcao: '',
                ref: null,
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/diagnosticosBase/importar-csv`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste seu arquivo aqui',
                dictRemoveFile: 'Remover'
            })
        }
    },
    methods: {
        adicionarVulnerabilidade(idx, novoValor) {
            const vulnerabilidades = this.form.perguntas[idx].vulnerabilidades;
            if (!vulnerabilidades.includes(novoValor)) vulnerabilidades.push(novoValor); 
        },
        adicionarRespostaNao(idx, novoValor) {
            const respostaNao = this.form.perguntas[idx].respostaNao;
            if (!respostaNao.includes(novoValor)) respostaNao.push(novoValor); 
        },
        // adicionarRespostaParcial(idx, novoValor) {
        //     const respostaParcial = this.form.perguntas[idx].respostaParcial;
        //     if (!respostaParcial.includes(novoValor)) respostaParcial.push(novoValor); 
        // },
        addPergunta() {
            this.form.perguntas.push( {
                pergunta: "",
                peso: 1,
                vulnerabilidades: [], 
                respostaNao: [],
                respostaParcial: [],
            });
            window.scrollTo(0, document.body.scrollHeight);
        },
        novoItem(item, index){
            this.formNovo.ref = index;
            if(item === 'vulnerabilidades'){
                this.formNovo.opcao = "vulnerabilidades";
            }
            if(item === 'respostaNao'){
                this.formNovo.opcao = "respostaNao";
            }
            if(item === 'respostaParcial'){
                this.formNovo.opcao = "respostaParcial";
            }
            this.$modal.show('novoItem');
        },
        async criarItem(){
            if(!this.formNovo.descricao) return this.$vToastify.error("Informe resposta para adicionar.");
            const index = this.formNovo.ref;
            const key = this.formNovo.opcao;

            if (!this.form.perguntas[index][key]) {
                this.form.perguntas[index][key] = [];
            }
            
            if (!this.form.perguntas[index][key].includes(this.formNovo.descricao)) {
                this.form.perguntas[index][key].push(this.formNovo.descricao);
            }else{
                return this.$vToastify.warning('Item já foi adicionado.');
            }

            this.$modal.hide('novoItem');
            this.formNovo.descricao = '';
            this.formNovo.opcao = '';
            this.formNovo.ref = null;

        },
        async save() {
            if(!this.form.tema) return this.$vToastify.error("Digite o tema");
            if(!this.form.perguntas.length) return this.$vToastify.warning('Nenhuma pergunta adicionada.');
            const metodo = this.form._id ? 'put' : 'post'; 
            
            await this.$http[metodo](`/v1/diagnosticosBase/`, this.form)
            .then((resp)=>this.$vToastify.success(resp.data.msg || ''))
            .catch((e)=>this.$vToastify.error(e?.response?.data?.err || ''))
    
            if(metodo === 'post') this.$router.back();
        },
        async remove(idx){
            this.form.perguntas.splice(idx, 1); 
        },
        async removerPerguntas(){
            this.$confirm({
            title: 'Confirmação',
            message: `Tem certeza que deseja remover todas as perguntas do diagnóstico?`,
            button: {
                no: 'Não',
                yes: 'Sim',
            },
            callback: async confirm => { if (confirm) this.form.perguntas = [] }
        });
        },
        changePeso(p, change) {
            if(change === 'diminuir' && p.peso > 0) p.peso--;
            if(change === 'adicionar') p.peso++;
        },
        send(file, xhr, formData) {
            formData.append('empresa', this.$store.state.empresa && this.$store.state.empresa._id ? this.$store.state.empresa._id : null);
            formData.append('idx', this.form.perguntas.length);
        },
        async update(file, response) {
            if(!response || !response.perguntas) return;
            this.form.perguntas.push(...response.perguntas);
            this.$modal.hide('importarModal');
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;
        if(id){
            await this.$http.get(`/v1/diagnosticosBase/${id}`)
            .then((resp)=> Object.assign(this.form, resp.data))
            .catch((e)=> this.$vToastify.error(e?.response?.data?.err || ''))
            
            await this.$http.get(`/v1/perguntaBase/diagnostico/${id}`)
            .then((resp)=> this.form.perguntas = resp.data)
            .catch((e)=> this.$vToastify.error(e?.response?.data?.err || ''))

        }
    },
}
</script>
